import {
  AiFillHome,
  BsPersonFill,
  BsPeopleFill,
  IoMdNotifications,
  IoIosSwitch,
  AiFillFolder,
  BsCreditCard2FrontFill,
  FaPen,
  FaFastForward,
  IoDocumentTextOutline,
  IoMailOpen,
} from 'react-icons/all';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useMemo } from 'react';
// import Grid from '@material-ui/core/Grid';
// import AlertTitle from '@material-ui/lab/AlertTitle';
// import Alert from '@material-ui/lab/Alert';
// import { useTranslation } from 'react-i18next';
import DoctorWelcomePage from 'pages/DoctorWelcomePage';
import DoctorResultsPage from 'pages/DoctorResultsPage';
import RegisterAndViewResultsPage from 'pages/RegisterAndViewResultsPages';
import MyPatiensFolder from 'pages/MyPatientsFolder';
import PatientDetailsPage from 'pages/customer-kits-overview/pages/PatientDetailsPage';
import iDNA3ToOne from 'pages/iDNA3ToOne/iDNA3ToOne';
import WeightControl from 'pages/iDNA3ToOne/WeightControl';
import CardiometabolicHealth from 'pages/iDNA3ToOne/CardiometabolicHealth';
import Vitamins from 'pages/iDNA3ToOne/Vitamins';
import FoodSensitivities from 'pages/iDNA3ToOne/FoodSensitivities';
import DetoxificationCapacityAntioxidantNeeds from 'pages/iDNA3ToOne/DetoxificationCapacityAntioxidantNeeds';
import TraceElements from 'pages/iDNA3ToOne/TraceElements';
import AthleticProfile from 'pages/iDNA3ToOne/AthleticProfile';
import InjuryPredisposition from 'pages/iDNA3ToOne/InjuryPredisposition';
import ThreeToOnePdfViewer from 'pages/iDNA3ToOne/ThreeToOnePdfViewer';
import RepeatNutrigenetix from 'pages/RepeatRegister/RepeatNutrigenetix';
import BuyRepeatNutrigenetix from 'pages/RepeatRegister/BuyRepeatNutrigenetix';
import RepeatNewQuestionnaire from 'pages/RepeatRegister/RepeatNewQuestionnaire';
import { boxAroundIconDoctor } from '../dashboard/navigationPages';
import CardBase from '../../app/CardBase';
// import NoDataPage from '../../../pages/NoDataPage';
import { useAuth, UserGroups } from '../../../AuthCtx';
import DoctorProfilePage from '../../../pages/DoctorProfilePage';
import DoctorPatientsPage from '../../../pages/DoctorPatientsPage';
import DoctorNotificationsPage from '../../../pages/DoctorNotificationsPage';
import { useNotifications } from '../../../models/notifications';
import DoctorConsentsPage from '../../../pages/DoctorConsentsPage';
import DoctorKitRegistrationDashboard, { DoctorKitRegistrationDashboardResume } from '../DoctorKitRegistrationDashboard';
import DoctorKitRegistrationDashboardAll from '../DoctorKitRegistrationDashboardAll';
import DoctorKitUpgrade from '../DoctorKitUpgrade';

const CardBaseWrapper = ({ children }) => {
  return (
    <CardBase withShadow align='left'>
      {children}
    </CardBase>
  );
};

/*
const DoctorGeneral = () => {
  const { userAttributes } = useAuth();
  const { t } = useTranslation();

  if (!userAttributes) {
    return null;
  }
  const verified = 'VERIFIED' === userAttributes.verificationStatus;

  if (!verified) {
    return (
      <Grid item xs={ 12 }>
        <Alert severity='info'>
          <AlertTitle>
            { t('Pending verification') }
          </AlertTitle>
          { t('Your registration is not yet completed. You will be notified with an email once completed.') }
        </Alert>
      </Grid>
    );
  }
  return (
    <NoDataPage title={ t('general') } />
  );
}; */

export const profileIconBig = () => {
  return (
    <div style={ boxAroundIconDoctor }>
      <AccountCircleIcon style={ { height: '50px', width: '50px' } } />
    </div>
  );
};

const registeredKitsIconBig = () => {
  return (
    <div style={ boxAroundIconDoctor }>
      <BsCreditCard2FrontFill style={ { height: '50px', width: '50px' } } />
    </div>
  );
};

const repeatIconBig = () => {
  return (
    <div style={ boxAroundIconDoctor }>
      <IoIosSwitch style={ { height: '50px', width: '50px' } } />
    </div>
  );
};

const resumeRegistrationKitsIconBig = () => {
  return (
    <div style={ boxAroundIconDoctor }>
      <FaFastForward style={ { height: '50px', width: '50px' } } />
    </div>
  );
};

const resultsDoctorIconBig = () => {
  return (
    <div style={ boxAroundIconDoctor }>
      <IoDocumentTextOutline style={ { height: '50px', width: '50px' } } />
    </div>
  );
};

const myPatientsIconBig = () => {
  return (
    <div style={ boxAroundIconDoctor }>
      <BsPeopleFill style={ { height: '50px', width: '50px' } } />
    </div>
  );
};

const notificationsIconBig = () => {
  return (
    <div style={ boxAroundIconDoctor }>
      <IoMdNotifications style={ { height: '50px', width: '50px' } } />
    </div>
  );
};

export const useDoctorIsAbleToGiveConsent = () => {
  const { userGroup, userAttributes } = useAuth();

  if (userGroup !== UserGroups.Doctor) {
    return false;
  }

  if (!userAttributes) {
    return false;
  }

  if ('VERIFIED' !== userAttributes.verificationStatus) {
    return false;
  }

  return [
    'ΝΕΥΡΟΛΟΓΙΑ',
    'ΨΥΧΙΑΤΡΙΚΗ',
  ].includes(userAttributes.specialty);
};

export const useCanViewKit = () => {
  const { userGroup, userAttributes } = useAuth();

  if ('VERIFIED' !== userAttributes.verificationStatus || userGroup !== UserGroups.Doctor || !userAttributes) {
    return {
      canViewCVDCheck: () => { return false; },
      canViewNutrigenetixCheck: () => { return false; },
      canViewWellnessCheck: () => { return false; },
      canViewRepeatCheck: () => { return false; },
      canViewBioAgeCheck: () => { return false; },
    };
  }

  const canViewCVDCheck = () => {
    return [
      'ΚΑΡΔΙΟΛΟΓΙΑ', 'ΔΙΑΒΗΤΟΛΟΓΙΑ', 'ΕΝΔΟΚΡΙΝΟΛΟΓΙΑ', 'ΓΕΝΙΚΗ ΙΑΤΡΙΚΗ', 'ΠΑΘΟΛΟΓΙΑ',
    ].includes(userAttributes.specialty);
  };

  const canViewNutrigenetixCheck = () => {
    return [ 'ΚΑΡΔΙΟΛΟΓΙΑ', 'ΔΙΑΒΗΤΟΛΟΓΙΑ', 'ΓΕΝΙΚΗ ΙΑΤΡΙΚΗ', 'ΠΑΘΟΛΟΓΙΑ', 'ΕΝΔΟΚΡΙΝΟΛΟΓΙΑ', 'ΔΙΑΙΤΟΛΟΓΙΑ - ΔΙΑΤΡΟΦΟΛΟΓΙΑ' ].includes(userAttributes.specialty);
  };

  const canViewRepeatCheck = () => {
    return [ 'ΔΙΑΙΤΟΛΟΓΙΑ - ΔΙΑΤΡΟΦΟΛΟΓΙΑ' ].includes(userAttributes.specialty);
  };

  const canViewWellnessCheck = () => {
    return [ 'ΔΙΑΙΤΟΛΟΓΙΑ - ΔΙΑΤΡΟΦΟΛΟΓΙΑ' ].includes(userAttributes.specialty);
  };

  return {
    canViewCVDCheck,
    canViewNutrigenetixCheck,
    canViewWellnessCheck,
    canViewRepeatCheck,
  };
};

const useDoctorNavigationPages = () => {
  const {
    notificationsCount,
  } = useNotifications(true);

  const doctorIsAbleToGiveConsent = useDoctorIsAbleToGiveConsent();

  const { canViewCVDCheck, canViewNutrigenetixCheck, canViewWellnessCheck, canViewRepeatCheck } = useCanViewKit();

  const { userAttributes } = useAuth();

  const canViewCVD = canViewCVDCheck();
  const canViewNutrigenetix = canViewNutrigenetixCheck();
  const canViewWellness = canViewWellnessCheck();
  const canViewRepeat = canViewRepeatCheck();

  const show3To1Email = process.env.REACT_APP_SHOW_3_TO_1_EMAIL?.split(',')?.map((s) => { return s.trim(); })?.includes(userAttributes.email);
  const showNutriGenetixEmail = process.env.REACT_APP_SHOW_NUTRIGENETIX_EMAIL?.split(',')?.map((s) => { return s.trim(); })?.includes(userAttributes.email);
  const showNutritionEmail = process.env.REACT_APP_SHOW_NUTRITION_EMAIL?.split(',')?.map((s) => { return s.trim(); })?.includes(userAttributes.email);
  const showCardioEmail = process.env.REACT_APP_SHOW_CARDIO_EMAIL?.split(',')?.map((s) => { return s.trim(); })?.includes(userAttributes.email);
  const showBioAgeEmail = process.env.REACT_APP_SHOW_BIOAGE_EMAIL?.split(',')?.map((s) => { return s.trim(); })?.includes(userAttributes.email);

  /*
   {
        id: 'general',
        title: 'general',
        Component: DoctorGeneral,
        wrapperComponent: CardBaseWrapper,
        Icon: AiFillHome,
        type: 'item',
      },
  */

  const navigationPages = useMemo(() => {
    return [
      {
        id: 'general',
        title: 'home',
        Component: DoctorWelcomePage,
        wrapperComponent: null,
        Icon: AiFillHome,
        type: 'item',
      },
      {
        id: 'patientDetails',
        title: 'patientDetails',
        Component: PatientDetailsPage,
        wrapperComponent: null,
        Icon: AiFillHome,
        type: 'item',
        isMenuHidden: true,
      },
      {
        id: 'patientQuestionnaire',
        title: 'patientQuestionnaire',
        Component: PatientDetailsPage,
        wrapperComponent: null,
        Icon: AiFillHome,
        type: 'item',
        isMenuHidden: true,
      },
      {
        id: 'register-and-view-folder',
        title: doctorIsAbleToGiveConsent ? 'Εγγραφή & αποτελέσματα ασθενών' : 'Εγγραφή & αποτελέσματα ασθενών/πελατών',
        Component: RegisterAndViewResultsPage,
        wrapperComponent: CardBaseWrapper,
        Icon: AiFillFolder,
        type: 'folder',
        isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus,
        items: [
          {
            id: 'registerNewKitDoctor',
            title: 'newKitRegistration',
            Component: DoctorKitRegistrationDashboardAll,
            wrapperComponent: CardBaseWrapper,
            Icon: FaPen,
            type: 'folder',
            BigIcon: registeredKitsIconBig,
            isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus,
            items: [
              {
                id: 'registerNewKitDoctorPGx',
                title: 'iDNA PGx CNS',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: registeredKitsIconBig,
                isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus || !doctorIsAbleToGiveConsent,
                // isMenuHidden: true,
              },
              {
                id: 'registerNewKitDoctorCardio',
                title: 'iDNA Cardio Health',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: registeredKitsIconBig,
                isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus || (!canViewCVD && true !== showCardioEmail),
              },
              {
                id: 'registerNewKitDoctorNutrigenetix',
                title: 'iDNA NutriGenetix',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: repeatIconBig,
                isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus || (!canViewNutrigenetix && true !== showNutriGenetixEmail),
                // isMenuHidden: true,
              },
              {
                id: 'registerNewKitDoctorBioAge',
                title: 'iDNA Βιολογική Ηλικία',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: registeredKitsIconBig,
                isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus || (!canViewWellness && true !== showBioAgeEmail),
              },
              {
                id: 'registerNewKitDoctor3In1',
                title: 'iDNA 3 σε 1',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: registeredKitsIconBig,
                isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus || (!canViewWellness && true !== show3To1Email),
              },
              {
                id: 'registerNewKitDoctorDiet',
                title: 'iDNA Διατροφή',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: registeredKitsIconBig,
                isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus || (!canViewWellness && true !== showNutritionEmail),
              },
            ],
          },
          {
            id: 'kitUpgrade',
            title: 'Αναβάθμιση kit',
            Component: DoctorKitUpgrade,
            wrapperComponent: CardBaseWrapper,
            Icon: BsCreditCard2FrontFill,
            type: 'folder',
            BigIcon: registeredKitsIconBig,
            // isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus,
            isMenuHidden: true,
            alternativeIds: [ 'registerNewUpgradableKit' ],
            items: [
              {
                id: 'registerNewUpgradableKit',
                title: '',
                Component: DoctorKitRegistrationDashboard,
                wrapperComponent: CardBaseWrapper,
                Icon: BsCreditCard2FrontFill,
                type: 'item',
                BigIcon: registeredKitsIconBig,
                isMenuHidden: true,
              },
            ],
          },
          {
            id: 'resumeRegisterKitDoctor',
            title: 'resumeKitRegistration',
            Component: DoctorKitRegistrationDashboardResume,
            wrapperComponent: CardBaseWrapper,
            Icon: FaFastForward,
            type: 'item',
            BigIcon: resumeRegistrationKitsIconBig,
            isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus,
          },
          {
            id: 'resultsDoctor',
            title: 'results',
            Component: DoctorResultsPage,
            wrapperComponent: CardBaseWrapper,
            Icon: IoDocumentTextOutline,
            type: 'item',
            BigIcon: resultsDoctorIconBig,
            isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus,
          },
        ],
      },
      {
        id: 'repeat-nutrigenetix',
        title: 'NutriGenetix Δυναμική Παρακολούθηση',
        Component: RepeatNutrigenetix,
        wrapperComponent: CardBaseWrapper,
        Icon: IoIosSwitch,
        isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus || (!canViewRepeat && true !== showNutriGenetixEmail),
        BigIcon: repeatIconBig,
        type: 'item',
      },

      {
        id: 'buy-repeat-nutrigenetix',
        title: '',
        Component: BuyRepeatNutrigenetix,
        wrapperComponent: CardBaseWrapper,
        Icon: IoIosSwitch,
        isMenuHidden: true,
        BigIcon: registeredKitsIconBig,
        type: 'item',
      },

      {
        id: 'new-repeat-questionnaire',
        title: '',
        Component: RepeatNewQuestionnaire,
        wrapperComponent: CardBaseWrapper,
        Icon: IoIosSwitch,
        isMenuHidden: true,
        BigIcon: registeredKitsIconBig,
        type: 'item',
      },

      {
        id: 'my-patients-folder',
        title: doctorIsAbleToGiveConsent ? 'Πρόσκληση παρακολούθησης ασθενών' : 'Πρόσκληση παρακολούθησης ασθενών/πελατών',
        Component: MyPatiensFolder,
        wrapperComponent: CardBaseWrapper,
        Icon: IoMailOpen,
        type: 'folder',
        BigIcon: registeredKitsIconBig,
        isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus,
        items: [
          {
            id: 'my-patients',
            title: doctorIsAbleToGiveConsent ? 'Λίστα ασθενών' : 'Λίστα ασθενών / πελατών',
            Component: DoctorPatientsPage,
            wrapperComponent: CardBaseWrapper,
            Icon: BsPeopleFill,
            type: 'item',
            BigIcon: myPatientsIconBig,
            isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus,
          },
        ],
      },
      {
        id: 'my-consents',
        title: 'my-consents',
        Component: DoctorConsentsPage,
        wrapperComponent: CardBaseWrapper,
        Icon: IoIosSwitch,
        isMenuHidden: !doctorIsAbleToGiveConsent,
        BigIcon: registeredKitsIconBig,
        type: 'item',
      },
      {
        id: 'notifications',
        title: 'notifications',
        Component: DoctorNotificationsPage,
        wrapperComponent: CardBaseWrapper,
        BigIcon: notificationsIconBig,
        Icon: IoMdNotifications,
        badgeProps: {
          count: notificationsCount,
        },
        type: 'item',
        isMenuHidden: 'VERIFIED' !== userAttributes?.verificationStatus,
      },
      {
        id: 'profile',
        title: 'profile',
        Component: DoctorProfilePage,
        wrapperComponent: CardBaseWrapper,
        BigIcon: profileIconBig,
        Icon: BsPersonFill,
        type: 'item',
      },

      {
        id: 'iDNATest3to1',
        title: 'iDNATest3to1',
        Component: iDNA3ToOne,
        wrapperComponent: null,
        type: 'folder',
        isMenuHidden: true,
        enabled: true,
        items: [
          {
            id: 'diet',
            alternativeIds: [
              'weightManagement', 'foodSensitivities', 'cardiometabolicHealth',
              'carbohydrates', 'proteins', 'snacksBetweenMeals', 'fats',
              'sweetTaste', 'biologicalClock', 'sensitivityToSalt',
              'saturatedFat', 'fattyAcids', 'transFats', 'sensitivityToCoffee',
              'sensitivityToAlcohol', 'sensitivityToLactose', 'sensitivityToGluten' ],
            title: 'diet',
            Component: WeightControl,
            wrapperComponent: null,
            type: 'item',
            color: '#F5C91E',
            isMenuHidden: true,
          },
          {
            id: 'weightManagement',
            title: 'weightManagement',
            Component: WeightControl,
            wrapperComponent: null,
            type: 'item',
            isMenuHidden: true,
          },
          {
            id: 'cardiometabolicHealth',
            title: 'cardiometabolicHealth',
            Component: CardiometabolicHealth,
            wrapperComponent: null,
            type: 'item',
            isMenuHidden: true,
          },

          {
            id: 'foodSensitivities',
            title: 'foodSensitivities',
            Component: FoodSensitivities,
            wrapperComponent: null,
            type: 'item',
            isMenuHidden: true,
          },

          {
            id: 'vitaminsHome',
            title: 'vitamins',
            alternativeIds: [ 'vitamins', 'detoxificationCapacityAntioxidantNeeds', 'traceElements',
              'vitaminA', 'vitaminB6', 'vitaminB9Acid', 'vitaminB12',
              'vitaminC', 'vitaminD', 'vitaminE', 'detoxificationCapacity',
              'antioxidantNeeds', 'lowCalcium', 'highCalcium', 'lowIron', 'highIron',
              'magnesium' ],
            Component: Vitamins,
            wrapperComponent: null,
            type: 'item',
            isMenuHidden: true,
          },

          {
            id: 'vitamins',
            title: 'vitamins',
            Component: Vitamins,
            wrapperComponent: null,
            type: 'item',
            isMenuHidden: true,
          },

          {
            id: 'detoxificationCapacityAntioxidantNeeds',
            title: 'detoxificationCapacityAntioxidantNeeds',
            Component: DetoxificationCapacityAntioxidantNeeds,
            wrapperComponent: null,
            type: 'item',
            isMenuHidden: true,
          },
          {
            id: 'traceElements',
            title: 'traceElements',
            Component: TraceElements,
            wrapperComponent: null,
            type: 'item',
            isMenuHidden: true,
          },

          {
            id: 'sports',
            title: 'sports',
            alternativeIds: [ 'injuryPredisposition', 'athleticProfile', 'stamina',
              'strength', 'power', 'aerobics', 'increaseMuscle', 'wantToExercise', 'painTolerance',
              'kneeElbowInjury', 'achillesTendonInjury', 'musculoskeletalHealth',
              'recoveryAfterExercise' ],
            Component: AthleticProfile,
            wrapperComponent: null,
            type: 'item',
            color: '#31B5E1',
            isMenuHidden: true,
          },

          {
            id: 'athleticProfile',
            title: 'athleticProfile',
            Component: AthleticProfile,
            wrapperComponent: null,
            type: 'item',
            isMenuHidden: true,
          },

          {
            id: 'injuryPredisposition',
            title: 'injuryPredisposition',
            Component: InjuryPredisposition,
            wrapperComponent: null,
            type: 'item',
            isMenuHidden: true,
          },

          {
            id: 'pdfVersion',
            title: 'PDF version',
            Component: ThreeToOnePdfViewer,
            wrapperComponent: CardBaseWrapper,
            type: 'item',
            isMenuHidden: true,
          },
        ],
      },
    ];
  }, [ notificationsCount, doctorIsAbleToGiveConsent, canViewCVD, canViewNutrigenetix,
    canViewWellness, show3To1Email, showNutritionEmail, showNutriGenetixEmail, canViewRepeat, showCardioEmail, showBioAgeEmail ]);

  return navigationPages;
};

export default useDoctorNavigationPages;

