/* eslint-disable no-unused-vars */
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { withStyles, TextField, Dialog, IconButton, Button, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme } from '@material-ui/styles';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import DownloadIcon from '@material-ui/icons/GetApp';
import useAPI from 'hooks/api-hooks';
import useMediaQueries from 'hooks/media-query-hooks';
import { StepperWithProgress } from 'pages/DoctorResultsPage';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import { parseDate } from 'utils';
import PDFViewer from '../PDFViewer';
import AppIconDelete from './app-icons/AppIconDelete';
import AppIconView from './app-icons/AppIconView';
import AppIconEdit from './app-icons/AppIconEdit';
import AdvancedFilters from './AdnvancedFilters';
import AppPagination from './AppPagination';

const RepeatRow = ({ row, columns, backgroundColor }) => {
  const [ selectedRepeatKit, setSelectedRepeatKit ] = useState(-1);

  const { repeatKits } = row;
  const { t } = useTranslation();

  const { setSerial } = useOptionSerial('3-in-1');
  const { navigateToPage } = useNavigation();

  useEffect(() => {
    setSelectedRepeatKit(repeatKits?.[0]?.kit_id);
  }, [ repeatKits ]);

  const selectedKit = useMemo(() => {
    return repeatKits.find((s) => { return s.kit_id === selectedRepeatKit; });
  }, [ selectedRepeatKit ]);

  const [ openPdfDialog, setOpenPdfDialog ] = useState(false);
  const { serial } = row;

  let statusLabel = selectedKit?.registrationStatus;
  if (statusLabel) {
    statusLabel = statusLabel[0].toUpperCase() + statusLabel?.slice(1);
  }

  return (
    <StyledTableRow
      hover
      role='checkbox'
      tabIndex={ -1 }
      key={ row.id }
      style={ { backgroundColor } }
    >
      {
        openPdfDialog && (
          <Dialog
            open={ openPdfDialog }
            onClose={ () => {
              setOpenPdfDialog(false);
            } }
            fullWidth
            maxWidth='lg'
          >
            <div style={ { padding: '30px', borderRadius: '15px', position: 'relative' } }>
              <IconButton
                style={ { position: 'absolute', top: '5px', right: '5px', padding: 0, margin: 0 } }
                onClick={ () => {
                  setOpenPdfDialog(false);
                } }
              >
                <CancelIcon style={ { color: 'red' } } />
              </IconButton>
              <PDFViewer serial={ selectedKit.serial } />
            </div>
          </Dialog>
        )
      }
      <StyledTableCell>
        {
          0 < repeatKits.length && (
            <>
              <Typography style={ { fontSize: '14px',
                position: 'absolute',
                width: '100%' } }
              >
                {t('Δυναμική παρακολούθηση')}
              </Typography>

              <FormControl fullWidth style={ { marginTop: '18px' } }>
                <Select
                  style={ {
                    width: '120px',
                  } }
                  color='secondary'
                  value={ selectedRepeatKit }
                  onChange={ (e) => { setSelectedRepeatKit(e.target.value); } }
                >
                  {
                    repeatKits.map((kit) => {
                      return (
                        <MenuItem
                          key={ kit.kit_id }
                          value={ kit.kit_id }
                        >
                          {parseDate(kit.created_at)}
                        </MenuItem>
                      );
                    })
                  }

                </Select>
              </FormControl>
            </>
          )

        }
      </StyledTableCell>
      <StyledTableCell />
      <StyledTableCell align='left'>
        <Button
          disabled={ 'done' !== selectedKit?.registrationStatus }
          style={ { textTransform: 'none',
            fontSize: '14px',
            padding: 0,
            margin: 0,
            width: 'fit-content',
            fontWeight: 400,
            textDecoration: 'underline',
            color: 'done' !== selectedKit?.registrationStatus ? undefined : '#526D9C' } }
          onClick={ () => {
            setSerial(selectedRepeatKit);
            navigateToPage({ pageId: 'patientQuestionnaire' });
          } }
        >
          <span>
            <SearchIcon style={ { position: 'relative', top: '9px' } } />
            {t('Δες το συμπληρωμένο ερωτηματολόγιο')}
          </span>
        </Button>
      </StyledTableCell>

      <StyledTableCell>
        <StepperWithProgress statusLabel={ statusLabel } />
      </StyledTableCell>

      <StyledTableCell>
        <Button
          variant='outlined'
          style={ { color: 'done' !== selectedKit?.registrationStatus ? '' : '#526D9C', fontSize: '14px' } }
          startIcon={ <DownloadIcon /> }
          size='small'
          disabled={ 'done' !== selectedKit?.registrationStatus }
          onClick={ () => {
            setOpenPdfDialog(true);
          } }
        >
          {t('Κατέβασμα report')}
        </Button>
      </StyledTableCell>

      <StyledTableCell />
    </StyledTableRow>
  );
};

const SentReportStatusSelect = ({ row, setDataLoading, refetch }) => {
  const { t } = useTranslation();
  const { client } = useAPI();

  return (
    <FormControl>
      {
        'NutriGenetix kit' === row.product || 'NutriGenetix Laboratories kit' === row.product ? (
          <div>
            <div>
              <Typography style={ { fontSize: '14px', fontWeight: 600 } }>
                {t('Διατροφογενετική Ανάλυση')}
              </Typography>

              <Select
                disabled={ 'done' !== row.registrationStatus || 'send_by_platform' === row.sendAnalysisReportStatus }
                value={ row.sendAnalysisReportStatus }
                onChange={ (e) => {
                  setDataLoading(true);
                  client.put(`registration-kits/${ row.kitId }`, {
                    sendAnalysisReportStatus: e.target.value,
                  }).then(() => {
                    return refetch();
                  }).finally(() => {
                    setDataLoading(false);
                  });
                } }
                style={ {
                  fontSize: '13px',
                  // marginLeft: '15px',
                } }
              >
                <MenuItem
                  style={ { fontSize: '14px' } }
                  value='not_sent'
                >
                  {t('notSend1')}
                </MenuItem>
                <MenuItem
                  style={ { fontSize: '14px' } }
                  value='send_individually'
                >
                  {t('sendIndividually1')}
                </MenuItem>
                <MenuItem
                  style={ { fontSize: '14px' } }
                  disabled
                  value='send_by_platform'
                >
                  {t('sendThroughPlatform1')}
                </MenuItem>
              </Select>
            </div>
            <div style={ { marginTop: '3px' } }>
              <Typography style={ { fontSize: '14px', fontWeight: 600 } }>
                {t('Πλάνο διατροφής')}
              </Typography>

              <Select
                disabled={ 'done' !== row.registrationStatus || 'send_by_platform' === row.sendDietReportStatus }
                value={ row.sendDietReportStatus }
                onChange={ (e) => {
                  setDataLoading(true);
                  client.put(`registration-kits/${ row.kitId }`, {
                    sendDietReportStatus: e.target.value,
                  }).then(() => {
                    return refetch();
                  }).finally(() => {
                    setDataLoading(false);
                  });
                } }
                style={ {
                  fontSize: '13px',
                  // marginLeft: '15px',
                } }
              >
                <MenuItem
                  style={ { fontSize: '14px' } }
                  value='not_sent'
                >
                  {t('notSend1')}
                </MenuItem>
                <MenuItem
                  style={ { fontSize: '14px' } }
                  value='send_individually'
                >
                  {t('sendIndividually1')}
                </MenuItem>
                <MenuItem
                  style={ { fontSize: '14px' } }
                  disabled
                  value='send_by_platform'
                >
                  {t('sendThroughPlatform1')}
                </MenuItem>
              </Select>
            </div>
          </div>
        ) : (
          <Select
            disabled={ 'done' !== row.registrationStatus || 'send_by_platform' === row.sendReportStatus }
            value={ row.sendReportStatus }
            onChange={ (e) => {
              setDataLoading(true);
              client.put(`registration-kits/${ row.kitId }`, {
                sendReportStatus: e.target.value,
              }).then(() => {
                return refetch();
              }).finally(() => {
                setDataLoading(false);
              });
            } }
            style={ {
              fontSize: '14px',
              // marginLeft: '15px',
            } }
          >
            <MenuItem
              style={ { fontSize: '14px' } }
              value='not_sent'
            >
              {t('notSend1')}
            </MenuItem>
            <MenuItem
              style={ { fontSize: '14px' } }
              value='send_individually'
            >
              {t('sendIndividually1')}
            </MenuItem>
            <MenuItem
              style={ { fontSize: '14px' } }
              disabled
              value='send_by_platform'
            >
              {t('sendThroughPlatform1')}
            </MenuItem>
          </Select>
        )
      }

    </FormControl>
  );
};

const PdfButton = ({ serial, pdfAvailable, registrationStatus, sendReportStatus, customerId,
  refetch, setDataLoading, kitId, thematicPackage, sendAnalysisReportStatus, sendDietReportStatus }) => {
  const [ openPdfDialog, setOpenPdfDialog ] = useState(false);

  const isDisabled = 'done' !== registrationStatus || 'not_sent' !== sendReportStatus;
  const isNutriAnalysisDisabled = 'done' !== registrationStatus || 'not_sent' !== sendAnalysisReportStatus;
  const isNutriDietDisabled = 'done' !== registrationStatus || 'not_sent' !== sendDietReportStatus;

  const { t } = useTranslation();
  const { client } = useAPI();
  const [ isDoctorPDF, setIsDoctorPDF ] = useState(false);
  const [ nameSuffix, setNameSuffix ] = useState('');
  const { isMdDown } = useMediaQueries();

  return (
    <>
      {
        openPdfDialog && (
          <Dialog
            open={ openPdfDialog }
            onClose={ () => {
              setNameSuffix('');
              setOpenPdfDialog(false);
            } }
            fullWidth
            maxWidth='lg'
          >
            <div style={ { padding: '30px', borderRadius: '15px', position: 'relative' } }>
              <IconButton
                style={ { position: 'absolute', top: '5px', right: '5px', padding: 0, margin: 0 } }
                onClick={ () => {
                  setNameSuffix('');
                  setOpenPdfDialog(false);
                } }
              >
                <CancelIcon style={ { color: 'red' } } />
              </IconButton>
              <PDFViewer serial={ serial } isDoctor={ isDoctorPDF } nameSuffix={ nameSuffix } />
            </div>
          </Dialog>
        )
      }

      {
        'iDNA PGx kit' === thematicPackage ? (
          <>
            <div style={ { marginBottom: '3px' } }>
              <Button
                variant='outlined'
                style={ { color: !pdfAvailable || 'done' !== registrationStatus ? '' : '#526D9C', fontSize: '14px' } }
                startIcon={ <DownloadIcon /> }
                size='small'
                disabled={ !pdfAvailable || 'done' !== registrationStatus }
                onClick={ () => {
                  setIsDoctorPDF(true);
                  setOpenPdfDialog(true);
                } }
              >
                {t('Doctors report')}
              </Button>
            </div>
            <Button
              variant='outlined'
              style={ { color: !pdfAvailable || 'done' !== registrationStatus ? '' : '#526D9C',
                fontSize: '14px' } }
              size='small'
              startIcon={ <DownloadIcon /> }
              disabled={ !pdfAvailable || 'done' !== registrationStatus }
              onClick={ () => {
                setIsDoctorPDF(false);
                setOpenPdfDialog(true);
              } }
            >
              {t('Patients report')}
            </Button>
          </>
        ) : 'NutriGenetix kit' === thematicPackage || 'NutriGenetix Laboratories kit' === thematicPackage ? (
          <div>
            <Typography style={ { fontSize: '14px', fontWeight: 600 } }>
              {t('Διατροφογενετική Ανάλυση')}
            </Typography>
            <div style={ { display: 'flex', flexWrap: isMdDown ? 'wrap' : 'nowrap', marginBottom: '3px' } }>
              <Button
                variant='outlined'
                style={ { color: !pdfAvailable || 'done' !== registrationStatus ? '' : '#526D9C', fontSize: '14px' } }
                startIcon={ <DownloadIcon /> }
                size='small'
                disabled={ !pdfAvailable || 'done' !== registrationStatus }
                onClick={ () => {
                  setNameSuffix('_analysis');
                  setOpenPdfDialog(true);
                } }
              >
                {t('Κατέβασμα report')}
              </Button>
              <Button
                variant='outlined'
                style={ { color: isNutriAnalysisDisabled ? '' : '#526D9C', fontSize: '14px' } }
                size='small'
                onClick={ () => {
                  setDataLoading(true);
                  client.post('sendPDFFileByEmail', {
                    filename: `${ serial }_analysis.pdf`,
                    customer_id: customerId,
                    registration_kit: kitId,
                  }).then(() => {
                    return refetch();
                  }).finally(() => {
                    setDataLoading(false);
                  });
                } }
                startIcon={ (
                  isNutriAnalysisDisabled
                    ? (
                      <img
                        src='/disabled_email_icon.png'
                        alt='disabled email icon'
                        style={ { height: '20px', width: '20px' } }
                      />
                    )
                    : (
                      <img
                        src='/email_icon.png'
                        alt='email icon'
                        style={ { height: '20px', width: '20px' } }
                      />
                    )
                ) }
                disabled={ isNutriAnalysisDisabled }
              >
                {t('sendByEmail')}
              </Button>
            </div>

            <Typography style={ { fontSize: '14px', fontWeight: 600 } }>
              {t('Πλάνο διατροφής')}
            </Typography>

            <div style={ { display: 'flex', flexWrap: isMdDown ? 'wrap' : 'nowrap', marginBottom: '3px' } }>
              <Button
                variant='outlined'
                style={ { color: !pdfAvailable || 'done' !== registrationStatus ? '' : '#526D9C', fontSize: '14px' } }
                startIcon={ <DownloadIcon /> }
                disabled={ !pdfAvailable || 'done' !== registrationStatus }
                size='small'
                onClick={ () => {
                  setNameSuffix('_diet');
                  setOpenPdfDialog(true);
                } }
              >
                {t('Κατέβασμα report')}
              </Button>

              <Button
                variant='outlined'
                style={ { color: isNutriDietDisabled ? '' : '#526D9C', fontSize: '14px' } }
                size='small'
                onClick={ () => {
                  setDataLoading(true);
                  client.post('sendPDFFileByEmail', {
                    filename: `${ serial }_diet.pdf`,
                    customer_id: customerId,
                    registration_kit: kitId,
                  }).then(() => {
                    return refetch();
                  }).finally(() => {
                    setDataLoading(false);
                  });
                } }
                startIcon={ (
                  isNutriDietDisabled
                    ? (
                      <img
                        src='/disabled_email_icon.png'
                        alt='disabled email icon'
                        style={ { height: '20px', width: '20px' } }
                      />
                    )
                    : (
                      <img
                        src='/email_icon.png'
                        alt='email icon'
                        style={ { height: '20px', width: '20px' } }
                      />
                    )
                ) }
                disabled={ isNutriDietDisabled }
              >
                {t('sendByEmail')}
              </Button>
            </div>
          </div>
        )
          : (
            <Button
              variant='outlined'
              style={ { color: !pdfAvailable || 'done' !== registrationStatus ? '' : '#526D9C', fontSize: '14px' } }
              startIcon={ <DownloadIcon /> }
              size='small'
              disabled={ !pdfAvailable || 'done' !== registrationStatus }
              onClick={ () => { setOpenPdfDialog(true); } }
            >
              {t('Κατέβασμα report')}
            </Button>
          )
      }

      {
        'NutriGenetix kit' !== thematicPackage && 'NutriGenetix Laboratories kit' !== thematicPackage && (
          <Button
            variant='outlined'
            style={ { color: isDisabled ? '' : '#526D9C', fontSize: '14px' } }
            size='small'
            onClick={ () => {
              setDataLoading(true);
              client.post('sendPDFFileByEmail', {
                filename: `${ serial }.pdf`,
                customer_id: customerId,
                registration_kit: kitId,
              }).then(() => {
                return refetch();
              }).finally(() => {
                setDataLoading(false);
              });
            } }
            startIcon={ (
              isDisabled
                ? (
                  <img
                    src='/disabled_email_icon.png'
                    alt='disabled email icon'
                    style={ { height: '20px', width: '20px' } }
                  />
                )
                : (
                  <img
                    src='/email_icon.png'
                    alt='email icon'
                    style={ { height: '20px', width: '20px' } }
                  />
                )
            ) }
            disabled={ isDisabled }
          >
            {t('sendByEmail')}
          </Button>
        )
      }

    </>
  );
};

const StyledTableCell = withStyles((theme) => {
  return {
    head: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      // verticalAlign: 'top',
    },
  };
})(TableCell);

const StyledTableRow = withStyles((theme) => {
  return {
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.alternate.light,
      },
    },
  };
})(TableRow);

function descendingComparator(a, b, orderBy) {
  const deepSelectorA = orderBy.split('.').reduce((acc, selector) => {
    return acc?.[selector];
  }, a);
  const deepSelectorB = orderBy.split('.').reduce((acc, selector) => {
    return acc?.[selector];
  }, b);
  if (deepSelectorB < deepSelectorA) {
    return -1;
  }
  if (deepSelectorB > deepSelectorA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return 'desc' === order
    ? (a, b) => {
      return descendingComparator(a, b, orderBy);
    }
    : (a, b) => {
      return -descendingComparator(a, b, orderBy);
    };
}

function stableSort(array, comparator) {
  if (!array) {
    return [];
  }
  const stabilizedThis = array.map((el, index) => {
    return [ el, index ];
  });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (0 !== order) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => {
    return el[0];
  });
}

function EnhancedTableHead(props) {
  const {
    classes,
    columns,
    order,
    orderBy,
    onRequestSort,
    showCheckbox,
  } = props;
  const createSortHandler = (property) => {
    return (event) => {
      onRequestSort(event, property);
    };
  };

  return (
    <TableHead>
      <StyledTableRow>
        {showCheckbox && (
          <StyledTableCell padding='checkbox' />
        )}
        {columns.map((col) => {
          return (
            <StyledTableCell
              key={ col.id }
              align={ true === col.centerLabel ? 'center' : 'left' }
              padding='normal'
              sortDirection={ orderBy === col.field ? order : false }
            >
              { false !== col.sortable ? (
                <TableSortLabel
                  active={ orderBy === col.field }
                  direction={ orderBy === col.field ? order : 'asc' }
                  onClick={ createSortHandler(col.field) }
                >
                  { col.label }
                  { orderBy === col.id ? (
                    <span className={ classes.visuallyHidden }>
                      { 'desc' === order ? 'sorted descending' : 'sorted ascending' }
                    </span>
                  ) : null }
                </TableSortLabel>
              ) : (<>{col.label}</>) }
            </StyledTableCell>
          );
        })}
        {
          props.showActions && (
            <StyledTableCell align='right' padding='default'>
              Actions
            </StyledTableCell>
          )
        }
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf([ 'asc', 'desc' ]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { advancedFiltersProps } = props;

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } container>
        {
          (advancedFiltersProps) ? (
            <Grid container>
              <AdvancedFilters
                properties={ advancedFiltersProps.properties }
                initialAndFilterOptions={ advancedFiltersProps.initialAndFilterOptions }
                onQueryChange={ advancedFiltersProps.onQueryChange }
              />
            </Grid>
          ) : null
        }
      </Grid>
    </Grid>
  );
};

EnhancedTableToolbar.propTypes = {
  advancedFiltersProps: PropTypes.object,
};

EnhancedTableToolbar.defaultProps = {
  advancedFiltersProps: undefined,
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      // minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  };
});

export default function AppTable(props) {
  const {
    refetch,
    data: rows,
    columns,
    loading,
    paginationProps: {
      page,
      limit,
      sort: {
        order,
        orderBy,
      },
      setPage,
      setLimit,
      setSort,
      count,
    },
    advancedFiltersProps,
    selectionProps,
    activeProps,
    searchProps,
    isDoctor,
  } = props;

  const classes = useStyles();

  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && 'asc' === order;
    setSort(property, isAsc ? 'desc' : 'asc');
  };

  let repeatSkipNum = 0;

  const [ dataLoading, setDataLoading ] = useState(false);

  const handleClick = (event, name) => {
    const { selected, setSelected } = selectionProps;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (-1 === selectedIndex) {
      newSelected = newSelected.concat(selected, name);
    } else if (0 === selectedIndex) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (0 < selectedIndex) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => {
    if (!selectionProps) {
      return false;
    }
    const { selected } = selectionProps;
    return -1 !== selected.indexOf(name);
  };

  const isActive = (name) => {
    if (!activeProps) {
      return false;
    }
    const { active } = activeProps;
    return -1 !== active.indexOf(name);
  };

  const showActions = [
    props.ViewProp?.handleView,
    props.EditProp?.handleEdit,
    props.DelProp?.handleDelete,
  ].some((handler) => {
    return handler !== undefined;
  });

  const handleSearchChange = _.debounce((v) => { return searchProps?.setSearch(v); }, 300);

  const [ search, setSearch ] = useState(searchProps.search || '');
  const { t } = useTranslation();

  const showCheckbox = !!selectionProps;
  return (
    <div className={ classes.root }>
      <Paper className={ classes.paper } elevation={ 0 }>
        <EnhancedTableToolbar
          advancedFiltersProps={ advancedFiltersProps }
        />
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          style={ { minHeight: '40px' } }
        >
          <Grid item>
            {searchProps && (
              <Grid container alignItems='center' spacing={ 1 }>
                <Grid item>

                  <TextField
                    placeholder={ t('search') }
                    size='small'
                    variant='outlined'
                    color='secondary'
                    value={ search }
                    onChange={ (e) => {
                      setSearch(e.target.value);
                      return handleSearchChange(e.target.value);
                    } }
                  />
                  {
                    isDoctor && (
                      <Tooltip
                        title={ <Typography style={ { fontSize: '17px' } }>{ t('Serial and last name search') }</Typography> }
                      >
                        <InfoIcon style={ { color: '#526D9C', fontSize: '20px' } } />
                      </Tooltip>
                    )
                  }
                </Grid>
              </Grid>
            ) }
          </Grid>
          <Grid item>
            <AppPagination page={ page } count={ count } limit={ limit } setPage={ setPage } />
          </Grid>
        </Grid>
        <TableContainer style={ { padding: theme.spacing(1, 0) } }>
          <Table
            className={ classes.table }
            aria-labelledby='tableTitle'
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              classes={ classes }
              columns={ columns }
              order={ order }
              orderBy={ orderBy }
              onRequestSort={ handleRequestSort }
              rowCount={ rows?.length }
              showActions={ showActions }
              showCheckbox={ showCheckbox }
            />
            {
              dataLoading || loading ? (
                <tbody style={ { display: 'table-row-group', verticalAlign: 'middle' } }>
                  <tr>
                    <td colSpan={ 1000 }>
                      {
                        Array.from(Array(limit).keys()).map((n) => {
                          return (
                            <Skeleton
                              style={ { margin: '16px 12px' } }
                              variant='rect'
                              height='16px'
                              key={ n }
                              animation='wave'
                            />
                          );
                        })
                      }
                    </td>
                  </tr>
                </tbody>
              ) : (
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const isItemActive = isActive(row.id);
                      const labelId = `enhanced-table-checkbox-${ index }`;

                      let doctorBackgroundColor = '#d2deef';
                      if (isDoctor && 'done' !== row.registrationStatus) {
                        doctorBackgroundColor = '#eaeff7';
                      }

                      let backgroundColor = '#d2deef';
                      let otherBackgroundColor = '';
                      if (isDoctor) {
                        if (0 === (index + repeatSkipNum) % 2) {
                          backgroundColor = '#eaeff7';
                          otherBackgroundColor = '#c8d3e6';
                        } else {
                          backgroundColor = '#c8d3e6';
                          otherBackgroundColor = '#eaeff7';
                        }
                      }

                      if ('repeat' === row.product) {
                        repeatSkipNum += 1;
                        return (
                          <RepeatRow
                            backgroundColor={ otherBackgroundColor }
                            row={ row }
                            columns={ columns }
                          />
                        );
                      }

                      return (
                        <StyledTableRow
                          role='checkbox'
                          aria-checked={ isItemSelected }
                          tabIndex={ -1 }
                          key={ row.id }
                          selected={ isItemSelected }
                          style={ { backgroundColor } }
                        >
                          {showCheckbox && (
                            <StyledTableCell padding='checkbox'>
                              <Checkbox
                                onClick={ (event) => {
                                  return handleClick(event, row.id);
                                } }
                                checked={ isItemSelected }
                                inputProps={ { 'aria-labelledby': labelId } }
                              />
                            </StyledTableCell>
                          )}
                          {
                            columns.map((col) => {
                              const deepSelectorValue = !col.field ? null : col.field.split('.').reduce((acc, selector) => {
                                return acc?.[selector];
                              }, row);
                              // eslint-disable-next-line react/display-name
                              const { customRenderer: CustomRenderer } = col;

                              if (true === col.isSentReportStatusSelect) {
                                return (
                                  <StyledTableCell key={ col.id } align='left'>
                                    <SentReportStatusSelect
                                      row={ row }
                                      setDataLoading={ setDataLoading }
                                      refetch={ refetch }
                                    />
                                  </StyledTableCell>
                                );
                              }

                              if (true === col.isPDFButton) {
                                return (
                                  <StyledTableCell key={ col.id } align='left'>
                                    <PdfButton
                                      kitId={ row.kitId }
                                      thematicPackage={ row.product }
                                      serial={ row.serial }
                                      pdfAvailable={ row.pdfAvailable }
                                      registrationStatus={ row.registrationStatus }
                                      sendReportStatus={ row.sendReportStatus }
                                      customerId={ row.customerId }
                                      refetch={ refetch }
                                      setDataLoading={ setDataLoading }
                                      sendDietReportStatus={ row.sendDietReportStatus }
                                      sendAnalysisReportStatus={ row.sendAnalysisReportStatus }
                                    />
                                  </StyledTableCell>
                                );
                              }

                              return (
                                <StyledTableCell key={ col.id } align='left'>
                                  {CustomRenderer ? (
                                    <CustomRenderer
                                      id={ row.id }
                                      value={ deepSelectorValue }
                                      row={ row }
                                    />
                                  ) : deepSelectorValue}
                                </StyledTableCell>
                              );
                            })
                          }
                          {showActions && (
                            <StyledTableCell align='right'>
                              <Grid container direction='column' alignItems='flex-end'>
                                <Grid item>
                                  <Grid container direction='row' alignItems='center'>
                                    {props.ViewProp?.handleView && (
                                      <Grid item>
                                        <AppIconView
                                          disabled={ props.ViewProp.disabledView }
                                          onClick={ (e) => {
                                            return props.ViewProp.handleView(e, row.id);
                                          } }
                                        />
                                      </Grid>
                                    )}
                                    {props.EditProp?.handleEdit && (
                                      <Grid item>
                                        <AppIconEdit
                                          disabled={ props.EditProp.disabledEdit }
                                          onClick={ (e) => {
                                            return props.EditProp.handleEdit(e, row.id);
                                          } }
                                        />
                                      </Grid>
                                    )}
                                    {props.DelProp?.handleDelete && (
                                      <Grid item>
                                        <AppIconDelete
                                          disabled={ props.DelProp.disabledDelete }
                                          onClick={ (e) => {
                                            return props.DelProp.handleDelete(e, row.id);
                                          } }
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              )
            }
          </Table>
        </TableContainer>
        { (2 < 1) && (
          <TablePagination
            rowsPerPageOptions={ [ 5, 10, 25 ] }
            component='div'
            count={ count || 0 }
            rowsPerPage={ limit }
            page={ page }
            onChangePage={ (e, p) => {
              return setPage(p);
            } }
            onChangeRowsPerPage={ (e) => {
              return setLimit(parseInt(e.target.value, 10));
            } }
          />
        ) }
      </Paper>
    </div>
  );
}
