import React, { useState, useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import {
  Grid,
  Icon,
  Typography,
  Divider,
  Button,
  MobileStepper,
} from '@material-ui/core';
import AppTable from 'components/app/AppTable';
import { useTranslation } from 'react-i18next';
import { useItems } from 'models/atoms/items-hook';
import CollectionKeys from 'models/atoms/collection-keys';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import { useDoctorIsAbleToGiveConsent } from 'components/layout/doctor-dashboard/navigationPages';
import { changeType, results, useStyles as useStylesResults, colorPerType, progressPerType } from './Results';
import useMediaQueries from '../hooks/media-query-hooks';

export const useStyles = makeStyles((_theme) => {
  return {
    filterButton: {
      borderRadius: '100px',
      color: '#3B3B3B',
      fontWeight: 'normal',
      background: '#E5E5E5',
      position: 'relative',
      paddingLeft: '33px',
      textTransform: 'none',
    },
    checkedFilterButton: {
      borderRadius: '100px',
      color: '#3B3B3B',
      fontWeight: 'normal',
      background: '#D0D0D0',
      position: 'relative',
      '&:hover': {
        background: '#D0D0D0',
      },
      paddingLeft: '33px',
      textTransform: 'none',
    },
    iconOnLeft: {
      left: '13px',
      position: 'absolute',
      top: 0,
      bottom: 0,

      height: '20px',
      width: '20px',

      marginTop: 'auto',
      marginBottom: 'auto',
    },
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

const LogoImageAndRepeat = ({ row }) => {
  const foundResult = results.find((result) => {
    return result.type === row.product
      || result.alt_type === row.product || result.other_alt_type === row.product;
  });

  if (!foundResult) {
    return null;
  }

  return (
    <div style={ { display: 'flex',
      flexDirection: 'column',
      overflowX: 'visible',
      whiteSpace: 'nowrap',
      position: 'relative' } }
    >
      {foundResult.title}
      <img
        src={ foundResult.icon }
        alt={ foundResult.title }
        style={ { width: '100px', height: '100px', ...foundResult.imageStyle } }
      />
    </div>
  );
};

export const StepperWithProgress = ({ statusLabel }) => {
  const { t } = useTranslation();

  const classes = useStylesResults({ color: colorPerType[statusLabel] });

  return (
    <div>
      {t(changeType[statusLabel])}

      <MobileStepper
        variant='progress'
        steps={ 5 }
        position='static'
        activeStep={ progressPerType[statusLabel] }
        style={ { width: 'min(80%, 250px)', color: 'red', marginTop: '10px', padding: 0 } }
        className={ classes.stepper }
        nextButton={ (
          <div style={ { display: 'none' } } />
        ) }
        backButton={ (
          <div style={ { display: 'none' } } />
        ) }
      />
    </div>
  );
};

const ResultsTable = ({ kits, search, setSearch, isLoading, pagination, refetch }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const { navigateToPage } = useNavigation();
  // eslint-disable-next-line no-unused-vars
  const { setSerial } = useOptionSerial('3-in-1');

  const isPgxDoctor = useDoctorIsAbleToGiveConsent();

  const allKits = useMemo(() => {
    return kits?.filter((kit) => {
      return null != kit.thematic_package && Object.prototype.hasOwnProperty.call(kit.thematic_package, 'id');
    })
      .flatMap((kit, id) => {
        const foundKit = results.find((result) => {
          return result.type === kit.thematic_package.thematic_category_id.name
      || result.alt_type === kit.thematic_package.thematic_category_id.name || result.other_alt_type === kit.thematic_package.thematic_category_id.name;
        });

        let type = kit.thematic_package.name;
        if ('Cardio Health' === type || 'iDNA Cardio Health' === type || 'iDNA Cardio Health kit' === type) {
          type = 'IDNA Cardio Health';
        }

        let { registrationStatus } = kit;

        if (true === kit.serial_number_id.sample_available && 'registration_done' === kit.registrationStatus) {
          registrationStatus = 'sample_received';
        }

        if (kit.serial_number_id.repeated_serials !== undefined) {
          const repeatSerials = _.cloneDeep(kit.serial_number_id.repeated_serials);
          if (0 < repeatSerials.length) {
            repeatSerials.sort((a, b) => { return a.created_at - b.created_at; });

            return [
              {
                id,
                kitId: kit.id,
                product: type,
                serial: kit.serial_number_id.serial,
                sendAnalysisReportStatus: kit.sendAnalysisReportStatus,
                sendDietReportStatus: kit.sendDietReportStatus,
                navTitle: foundKit?.navTitle,
                icon: foundKit?.icon,
                title: foundKit?.title,
                style: foundKit?.style,
                fields: foundKit?.fields,
                status: 'active',
                pdfAvailable: kit.pdf_available,
                registrationStatus,
                sendReportStatus: kit.sendReportStatus,
                familyName: kit.customer_id?.family_name ?? '',
                firstName: kit.customer_id?.name ?? '',
                email: kit.customer_id?.email ?? '',
                customerId: kit.customer_id?.id,
              },

              {
              // eslint-disable-next-line prefer-template
                id: id + '\'' + repeatSerials.length + '\'',
                kitId: kit.id,
                product: 'repeat',
                serial: kit.serial_number_id.serial,
                sendAnalysisReportStatus: kit.sendAnalysisReportStatus,
                sendDietReportStatus: kit.sendDietReportStatus,
                navTitle: foundKit?.navTitle,
                icon: foundKit?.icon,
                title: foundKit?.title,
                style: foundKit?.style,
                fields: foundKit?.fields,
                status: 'active',
                pdfAvailable: kit.pdf_available,
                registrationStatus,
                sendReportStatus: kit.sendReportStatus,
                familyName: kit.customer_id?.family_name ?? '',
                firstName: kit.customer_id?.name ?? '',
                email: kit.customer_id?.email ?? '',
                customerId: kit.customer_id?.id,
                repeatKits: repeatSerials,
              },
            ];
          }
        }

        return {
          id,
          kitId: kit.id,
          product: type,
          serial: kit.serial_number_id.serial,
          sendAnalysisReportStatus: kit.sendAnalysisReportStatus,
          sendDietReportStatus: kit.sendDietReportStatus,
          navTitle: foundKit?.navTitle,
          icon: foundKit?.icon,
          title: foundKit?.title,
          style: foundKit?.style,
          fields: foundKit?.fields,
          status: 'active',
          pdfAvailable: kit.pdf_available,
          registrationStatus,
          sendReportStatus: kit.sendReportStatus,
          familyName: kit.customer_id?.family_name ?? '',
          firstName: kit.customer_id?.name ?? '',
          email: kit.customer_id?.email ?? '',
          customerId: kit.customer_id?.id,
        };
      });
  }, [ kits, isLoading ]);

  const tableColumns = [
    { id: 1,
      label: t('product'),
      sortable: false,
      field: 'product',
      customRenderer({ row }) {
        return (
          <LogoImageAndRepeat row={ row } />
        );
      } },
    { id: 2, label: 'Serial Number', field: 'serial', sortable: false },
    { id: 3,
      label: isPgxDoctor ? t('patFirstLastName') : t('patCustFirstLastName'),
      field: 'firstName',
      sortable: false,
      customRenderer({ row }) {
        return (
          <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } }>
            <span style={ { paddingLeft: '10px' } }>
              { row.firstName }
              {' '}
              { row.familyName }
            </span>
            <Button
              style={ { textTransform: 'none',
                fontSize: '14px',
                padding: 0,
                margin: 0,
                fontWeight: 400,
                textDecoration: 'underline',
                color: '#526D9C' } }
              onClick={ () => {
                setSerial(row.kitId);
                navigateToPage({ pageId: 'patientDetails' });
              } }
            >
              <span>
                <SearchIcon style={ { position: 'relative', top: '9px' } } />
                {t('Δες τα πλήρη στοιχεία ασθενή/πελάτη')}
              </span>
            </Button>
          </div>
        );
      } },
    { id: 6,
      label: 'Report status',
      field: 'registrationStatus',
      sortable: false,
      customRenderer({ row }) {
        let statusLabel = row.registrationStatus;
        statusLabel = statusLabel[0].toUpperCase() + statusLabel.slice(1);

        return (
          <StepperWithProgress statusLabel={ statusLabel } />
        );
      } },
    {
      id: 9,
      label: 'Report',
      sortable: false,
      isPDFButton: true,
      centerLabel: false,
    },
    { id: 8,
      field: 'sendReportStatus',
      label: t('Status αποστολής report'),
      isSentReportStatusSelect: true,
      sortable: false },
  ];

  return (
    <AppTable
      refetch={ refetch }
      isDoctor
      columns={ tableColumns }
      data={ allKits }
      loading={ isLoading }
      searchProps={ {
        search,
        setSearch,
      } }
      paginationProps={ pagination }
    />
  );
};

const DoctorResultsPageDesktop = () => {
  const classes = useStyles();

  const { isMd } = useMediaQueries();
  const { t } = useTranslation();

  const [ sendByPlatformFilter, setSendByPlatformFilter ] = useState(true);
  const [ notSentFilter, setNotSendFilter ] = useState(true);
  const [ sendIndividuallyFilter, setSendIndividuallyFilter ] = useState(true);

  const [ search, setSearch ] = useState('');

  const [ filterQuery, setFilterQuery ] = useState({ });

  useEffect(() => {
    let searchQueryKey = { };

    if ('' !== search) {
      searchQueryKey = {
        _where: {
          _or: [
            [{ 'serial_number_id.serial_contains': search }],
            [{ customer_id_null: false }, { 'customer_id.family_name_contains': search }],
          ],
        },
      };
    }

    const sendReportStatusForbidden = [];

    if (!sendIndividuallyFilter) {
      sendReportStatusForbidden.push('send_individually');
    }

    if (!notSentFilter) {
      sendReportStatusForbidden.push('not_sent');
    }

    if (!sendByPlatformFilter) {
      sendReportStatusForbidden.push('send_by_platform');
    }

    setFilterQuery({
      sendReportStatus_nin: sendReportStatusForbidden,
      ...searchQueryKey,
    });
  }, [ search, sendByPlatformFilter, notSentFilter, sendIndividuallyFilter ]);

  const {
    items: kits,
    itemsLoading: isLoading,
    pagination,
    refetch,
  } = useItems(CollectionKeys.DoctorSortedKits, { defaultPagination: { limit: 5 },
    filterQuery: { ...filterQuery, 'serial_number_id.ancestor_null': true } });

  return (
    <Grid container spacing={ isMd ? 4 : 2 }>
      <Grid item xs={ 12 }>
        <div className={ classes.titleCta }>
          <Typography variant='h6' color='textPrimary'>
            {t('results')}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={ 12 }>
        <Divider />
      </Grid>
      <Grid container item xs={ 12 } spacing={ 1 } style={ { justifyContent: 'flex-end' } }>
        <Grid item>
          <Button
            variant='contained'
            startIcon={
              sendByPlatformFilter ? <CheckIcon className={ classes.iconOnLeft } /> : <Icon className={ classes.iconOnLeft } />
            }
            onClick={ () => {
              setSendByPlatformFilter((val) => {
                return !val;
              });
            } }
            className={ sendByPlatformFilter ? classes.checkedFilterButton : classes.filterButton }
          >
            {t('sendThroughPlatform')}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant='contained'
            startIcon={
              sendIndividuallyFilter ? <CheckIcon className={ classes.iconOnLeft } /> : <Icon className={ classes.iconOnLeft } />
            }
            onClick={ () => {
              setSendIndividuallyFilter((val) => {
                return !val;
              });
            } }
            className={ sendIndividuallyFilter ? classes.checkedFilterButton : classes.filterButton }
          >
            {t('sendIndividually')}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant='contained'
            startIcon={
              notSentFilter ? <CheckIcon className={ classes.iconOnLeft } /> : <Icon className={ classes.iconOnLeft } />
            }
            onClick={ () => {
              setNotSendFilter((val) => {
                return !val;
              });
            } }
            className={ notSentFilter ? classes.checkedFilterButton : classes.filterButton }
          >
            {t('notSend')}
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={ 12 }>
        <Grid item xs={ 12 }>
          <ResultsTable
            pagination={ pagination }
            kits={ kits }
            search={ search }
            setSearch={ setSearch }
            refetch={ refetch }
            isLoading={ isLoading }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const DoctorResultsPage = () => {
  return (
    <div style={ { width: '100%' } }>
      <DoctorResultsPageDesktop />
    </div>
  );
};

export default DoctorResultsPage;
