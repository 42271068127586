import React, { useMemo, useState } from 'react';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { Dialog, Grid, DialogTitle, Typography, DialogContent, FormControl, RadioGroup, Radio,
  TextField, FormControlLabel, InputLabel, MenuItem, DialogActions, Select, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAPI from 'hooks/api-hooks';
import { useAlerts } from 'hooks/alerts-hooks';
import useMediaQueries from 'hooks/media-query-hooks';
import { countries } from './atoms/CountrySelectField';

const Payment = ({ sureDialogOpen, setSureDialogOpen, paymentDetails, setPaymentDetails,
  // eslint-disable-next-line no-unused-vars
  handlePGxKitSubmit, isKitPGX, isKitOnlyNutri, isKitCvd, paymentType, setPaymentType, kitName, cost,
  hideBankDeposit = false, thematicPackage }) => {
  const { t } = useTranslation();

  const { isSm } = useMediaQueries();

  const [ checkErrors, setCheckErrors ] = useState(false);

  const [ promoCode, setPromoCode ] = useState('');

  const { alertSuccess } = useAlerts();

  const { client } = useAPI();

  const [ promoCodeApplied, setPromoCodeApplied ] = useState(false);

  const [ costAfterPromo, setCostAfterPromo ] = useState(cost);
  const [ promoCodeEntity, setPromoCodeEntity ] = useState(undefined);

  const areDetailsMissing = useMemo(() => {
    return null == paymentDetails || '' === paymentDetails.first_name || null == paymentDetails.first_name
    || '' === paymentDetails.last_name || null == paymentDetails.last_name
    || '' === paymentDetails.billing_address || null == paymentDetails.billing_address
    || '' === paymentDetails.billing_zip_code || null == paymentDetails.billing_zip_code
    || '' === paymentDetails.billing_city || null == paymentDetails.billing_city
    || '' === paymentDetails.billing_country || null == paymentDetails.billing_country
    || '' === paymentDetails.customer_email || null == paymentDetails.customer_email
    || '' === paymentDetails.customer_phone || null == paymentDetails.customer_phone;
  }, [ paymentDetails ]);

  const validateField = (value) => {
    return null != value && '' !== value;
  };

  const handleFormSubmit = () => {
    if ('bank' === paymentType) {
      return true;
    }
    if (![
      () => { return validateField(paymentDetails.first_name); },
      () => { return validateField(paymentDetails.last_name); },
      () => { return validateField(paymentDetails.billing_address); },
      () => { return validateField(paymentDetails.billing_zip_code); },
      () => { return validateField(paymentDetails.billing_city); },
      () => { return validateField(paymentDetails.billing_country); },
      () => { return validateField(paymentDetails.customer_email); },
      () => { return validateField(paymentDetails.customer_phone); },
      () => { return validateField(paymentDetails.installments); },
    ].every((f) => {
      return true === f();
    })) {
      setCheckErrors(true);
      return false;
    }

    return true;
  };

  const logo = 'NutriGenetix Δυναμική Παρακολούθηση' === kitName ? 'repeat.png'
    : isKitCvd ? '/CVD_logo.png' : isKitOnlyNutri ? 'Nutrigenetix_logo.png'
      : '/pgx_logo.png';

  const applyPromoCode = () => {
    return client.post('/promotion-codes/validation', {
      code: promoCode,
      thematic_package: thematicPackage,
    }).then((dd) => {
      alertSuccess(t('Promo code was applied successfully'));
      setCostAfterPromo(dd.amount);
      setPromoCodeEntity(dd);
      setPromoCodeApplied(true);
      return dd;
    }).catch((e) => {
      console.error('e', e);
    });
  };

  return (
    <Dialog
      open={ sureDialogOpen }
      onClose={ () => { setSureDialogOpen(false); } }
      aria-labelledby='alert-dialog-title-review'
      aria-describedby='alert-dialog-description-review'
      maxWidth='sm'
      fullWidth
    >
      <Grid container>
        <Grid
          item
          xs={ 12 }
        >
          <DialogContent style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column' } }>
            <div style={ { display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingTop: '10px', alignItems: 'center' } }>
              <div>
                <img
                  src={ logo }
                  alt={ logo }
                  style={ {
                    height: '80px',
                    boxShadow: 'NutriGenetix Δυναμική Παρακολούθηση' !== kitName ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px' : '',
                  } }
                />
              </div>

              <Typography
                variant='body2'
                style={ { fontWeight: 500,
                  fontSize: '1.4rem',
                  marginTop: '5px' } }
              >
                {('iDNA NutriGenetix kit' === kitName || 'NutriGenetix Laboratories kit' === kitName)
                  ? 'iDNA NutriGenetix Kit' : t(kitName)}
              </Typography>
            </div>

            <div
              style={ { display: 'flex',
                justifyContent: 'flex-start',
                marginTop: '20px',
                alignItems: 'center',
                gap: '5px' } }
            >
              <Typography
                variant='body1'
                style={ {
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                } }
              >
                {t('price')}
                :
              </Typography>

              <Typography
                style={ {
                  fontWeight: 'bold',
                  fontSize: '1.3rem',
                } }
              >
                {
                  costAfterPromo !== cost ? (
                    <>
                      <span
                        style={ {
                          textDecoration: 'line-through',
                          fontWeight: 'bold',
                          fontSize: '1.3rem',
                          color: 'rgba(0, 0, 0, 0.26)',
                        } }
                      >
                        {cost}
                      </span>
                      {' '}
                      {costAfterPromo}
                    </>
                  ) : costAfterPromo
                }
                {' '}
                €
              </Typography>
            </div>
          </DialogContent>
        </Grid>

        <Grid
          item
          xs={ 12 }
          style={ {
            display: 'flex', justifyContent: 'center',
          } }
        >
          <TextField
            variant={ costAfterPromo !== cost ? 'standard' : 'outlined' }
            size='small'
            color='secondary'
            disabled={ costAfterPromo !== cost }
            value={ promoCode }
            style={ {
              marginRight: '0',
              color: costAfterPromo !== cost ? 'black' : '',
            } }
            label={ costAfterPromo !== cost ? '' : t('reg_kit.review.common.coupon_code') }
            onChange={ (e) => {
              setPromoCode(e.target.value);
            } }
            InputProps={ {
              style: {
                color: 'black',
              },
              disableUnderline: true,
              endAdornment: (
                <>
                  {
                    !isSm && (
                      <div>
                        {/* @ts-ignore */}
                        <Button
                          variant='contained'
                          disabled={ costAfterPromo !== cost }
                          style={ {
                            minWidth: '180px',
                            position: 'relative',
                            marginRight: '-14px',
                            color: 'white',
                            textTransform: 'none',
                          } }
                          color='secondary'
                          onClick={ applyPromoCode }
                        >
                          {t('reg_kit.review.common.coupon_use')}
                        </Button>
                      </div>
                    )
                  }
                </>
              ),
            } }
          />
        </Grid>

        {
          isSm && (
            <Grid
              item
              xs={ 12 }
              style={ {
                display: 'flex', justifyContent: 'center',
              } }
            >
              <Button
                variant='contained'
                disabled={ costAfterPromo !== cost }
                fullWidth
                style={ {
                  color: 'white',
                  textTransform: 'none',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  maxWidth: '222px',
                  marginTop: '5px',
                } }
                color='secondary'
                onClick={ applyPromoCode }
              >
                {t('reg_kit.review.common.coupon_use')}
              </Button>
            </Grid>
          )
        }

        {
          !hideBankDeposit && (
            <Grid
              item
              xs={ 12 }
              style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }
            >
              <div>
                <DialogTitle style={ { paddingBottom: 0 } }>
                  <Typography variant='h5' style={ { fontSize: '1.3rem' } }>
                    { t('Επιλέξτε τρόπο πληρωμής') }
                  </Typography>
                </DialogTitle>

                <DialogContent>
                  <FormControl
                    component='fieldset'
                    fullWidth
                    style={ { } }
                  >
                    <RadioGroup
                      value={ paymentType }
                      onChange={ (e) => { setPaymentType(e.target.value); } }
                    >

                      <FormControlLabel
                        value='credit-card'
                        control={ <Radio /> }
                        label={ (
                          <div style={ { display: 'flex', alignItems: 'center', gap: '5px' } }>
                            <CreditCardIcon />
                            {
                              t('Με κάρτα')
                            }
                          </div>
                        ) }
                      />

                      <FormControlLabel
                        value='bank'
                        control={ <Radio /> }
                        label={ (
                          <div style={ { display: 'flex', alignItems: 'center', gap: '5px' } }>
                            <AccountBalanceIcon />
                            {
                              t('Κατάθεση στην τράπεζα')
                            }
                          </div>
                        ) }
                      />
                    </RadioGroup>
                  </FormControl>

                </DialogContent>

              </div>

            </Grid>
          )
        }

        {
          'bank' === paymentType && (
            <Grid item xs={ 12 } style={ { display: 'flex', justifyContent: 'center' } }>
              <Typography variant='subtitle1' style={ { maxWidth: '85%', textAlign: 'center' } }>
                { t('Θα λάβετε e-mail με τους διαθέσιμους λογαριασμούς μετά την ολοκλήρωση της εγγραφής σας.') }
              </Typography>
            </Grid>
          )
        }

      </Grid>

      {
        'credit-card' === paymentType && (
          <>
            <hr style={ { width: '80%', height: '1px', background: '#f2f2f2', opacity: '60%' } } />
            <DialogTitle id='alert-dialog-title-review' style={ { paddingTop: 0 } }>
              { t('Στοιχεία χρέωσης') }
            </DialogTitle>
            <DialogContent style={ {
              minHeight: '200px',
            } }
            >
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    value={ paymentDetails.first_name }
                    variant='outlined'
                    label={ t('common.first_name') }
                    lettersOnly
                    fullWidth
                    required
                    error={ checkErrors && !validateField(paymentDetails.first_name) }
                    helperText={ checkErrors && !validateField(paymentDetails.first_name) && t('validation.requiredField') }
                    color='secondary'
                    onChange={ (e) => {
                      setPaymentDetails((prev) => {
                        prev.first_name = e.target.value;
                        return { ...prev };
                      });
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    required
                    value={ paymentDetails.last_name }
                    variant='outlined'
                    lettersOnly
                    label={ t('common.last_name') }
                    color='secondary'
                    error={ checkErrors && !validateField(paymentDetails.last_name) }
                    helperText={ checkErrors && !validateField(paymentDetails.last_name) && t('validation.requiredField') }
                    fullWidth
                    onChange={ (e) => {
                      setPaymentDetails((prev) => {
                        prev.last_name = e.target.value;
                        return { ...prev };
                      });
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    value={ paymentDetails.billing_address }
                    required
                    variant='outlined'
                    label={ t('common.street_and_number') }
                    color='secondary'
                    error={ checkErrors && !validateField(paymentDetails.billing_address) }
                    helperText={ checkErrors && !validateField(paymentDetails.billing_address) && t('validation.requiredField') }
                    fullWidth
                    onChange={ (e) => {
                      setPaymentDetails((prev) => {
                        prev.billing_address = e.target.value;
                        return { ...prev };
                      });
                    } }
                  />

                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    value={ paymentDetails.billing_zip_code }
                    variant='outlined'
                    label={ t('common.postal_code') }
                    required
                    color='secondary'
                    error={ checkErrors && !validateField(paymentDetails.billing_zip_code) }
                    helperText={ checkErrors && !validateField(paymentDetails.billing_zip_code) && t('validation.requiredField') }
                    fullWidth
                    onChange={ (e) => {
                      setPaymentDetails((prev) => {
                        prev.billing_zip_code = e.target.value;
                        return { ...prev };
                      });
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    value={ paymentDetails.billing_city }
                    variant='outlined'
                    label={ t('common.city') }
                    error={ checkErrors && !validateField(paymentDetails.billing_city) }
                    helperText={ checkErrors && !validateField(paymentDetails.billing_city) && t('validation.requiredField') }
                    color='secondary'
                    required
                    fullWidth
                    onChange={ (e) => {
                      setPaymentDetails((prev) => {
                        prev.billing_city = e.target.value;
                        return { ...prev };
                      });
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <FormControl required fullWidth variant='outlined' color='secondary'>
                    <InputLabel
                      color='secondary'
                      id='select-label-country'
                    >
                      {t('country')}
                    </InputLabel>
                    <Select
                      variant='outlined'
                      color='secondary'
                      labelId='select-label-country'
                      required
                      value={ paymentDetails.billing_country }
                      label={ `${ t('common.country') } *` }
                      onChange={ (e) => {
                        setPaymentDetails((prev) => {
                          prev.billing_country = e.target.value;
                          return { ...prev };
                        });
                      } }
                    >
                      {
                        countries.map((coun) => {
                          return (
                            <MenuItem
                              key={ coun.value }
                              value={ coun.value }
                            >
                              {t(coun.name)}
                            </MenuItem>
                          );
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={ 12 }>
                  <TextField
                    value={ paymentDetails.customer_email }
                    variant='outlined'
                    label={ t('common.email') }
                    error={ checkErrors && !validateField(paymentDetails.customer_email) }
                    helperText={ checkErrors && !validateField(paymentDetails.customer_email) && t('validation.requiredField') }
                    color='secondary'
                    fullWidth
                    required
                    onChange={ (e) => {
                      setPaymentDetails((prev) => {
                        prev.customer_email = e.target.value;
                        return { ...prev };
                      });
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    value={ paymentDetails.customer_phone }
                    variant='outlined'
                    label={ t('common.mobile_number') }
                    color='secondary'
                    fullWidth
                    error={ checkErrors && !validateField(paymentDetails.customer_phone) }
                    helperText={ checkErrors && !validateField(paymentDetails.customer_phone) && t('validation.requiredField') }
                    required
                    onChange={ (e) => {
                      setPaymentDetails((prev) => {
                        prev.customer_phone = e.target.value;
                        return { ...prev };
                      });
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <FormControl required fullWidth variant='outlined'>
                    <InputLabel
                      color='secondary'
                      id='select-label-installments'
                    >
                      {`${ t('reg_kit.review.non_doctor.pgx.installments') }`}
                    </InputLabel>
                    <Select
                      variant='outlined'
                      color='secondary'
                      labelId='select-label-installments'
                      id='select-installments'
                      required
                      value={ paymentDetails.installments }
                      label={ `${ t('reg_kit.review.non_doctor.pgx.installments') }` }
                      onChange={ (e) => {
                        setPaymentDetails((prev) => {
                          prev.installments = e.target.value;
                          return { ...prev };
                        });
                      } }
                    >
                      <MenuItem value={ 0 }>{t('reg_kit.review.non_doctor.pgx.no_installments')}</MenuItem>
                      <MenuItem value={ 2 }>2</MenuItem>
                      <MenuItem value={ 3 }>3</MenuItem>
                      <MenuItem value={ 4 }>4</MenuItem>
                      <MenuItem value={ 5 }>5</MenuItem>
                      <MenuItem value={ 6 }>6</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
            </DialogContent>
          </>
        )
      }

      <DialogActions style={ { position: 'relative', paddingTop: '30px' } }>

        {areDetailsMissing && 'bank' !== paymentType && (
          <span style={ { color: 'red',
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: '8px',
            marginTop: '3px' } }
          >
            *
            {t('Δεν έχουν συμπληρωθεί όλα τα στοιχεία')}
          </span>
        )}

        <Button
          variant='outlined'
          style={ { background: 'red', color: 'white' } }
          onClick={ () => {
            setSureDialogOpen(false);
          } }
        >
          {t('common.cancel')}
        </Button>

        <Button
          variant='contained'
          color='secondary'
          error={ areDetailsMissing }
          style={ { color: 'white' } }
          onClick={ async () => {
            if (!handleFormSubmit()) {
              return;
            }

            let xcostAfterPromo = costAfterPromo;
            let xPromoEntity = promoCodeEntity;

            if ('' !== promoCode && promoCode !== undefined && false === promoCodeApplied) {
              const xpromoCode = await applyPromoCode();
              if (xpromoCode === undefined) {
                return;
              }

              xcostAfterPromo = xpromoCode.amount;
              xPromoEntity = xpromoCode;
            }

            setSureDialogOpen(false);
            handlePGxKitSubmit(xcostAfterPromo, xPromoEntity);
          } }
        >
          {t('reg_kit.review.non_doctor.pgx.pay_kit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Payment;
